.outside {
    border: 2px solid black;
    padding: 10px 13px 13px 10px;
    border-radius: 20px;
    position: relative;
}

.padding-top {
    padding-top: 40px;
}

.padding-left {
    padding-left: 40px;
}

.padding-bottom {
    padding-bottom: 40px;
}

.padding-right {
    padding-right: 40px;
}

.center {
    height: 100%;
    width: 100%;
    background-color: black;
}

.textTop {
    position: absolute;
    top: 5px;
}

.textRight {
    position: absolute;
    transform: rotate(90deg);
}

.textLeft {
    position: absolute;
    transform: rotate(-90deg);
}

.textBottom {
    position: absolute;
    bottom: 8px;
}
