.subCategoryTable {
    position: relative;
}

.subCategoryTable .MuiCard-root {
    box-shadow: none;
    border-radius: 0;
}

.subCategoryTable [class*='RaListToolbar-actions'] {
    padding-top: 0;
    padding-bottom: 0;
}

.subCategoryTable [class*='RaListToolbar-toolbar'] {
    min-height: 10px;
}

.subCategoryTable [class*='RaTopToolbar-root'] {
    padding-top: 0;
    padding-bottom: 0;
}
