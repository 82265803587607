.categoriesTable {
    position: relative;
}

.categoriesTable .MuiCard-root {
    box-shadow: none;
}

.categoriesTable [class*='RaListToolbar-actions'] {
    padding-top: 0;
    padding-bottom: 0;
}

.categoriesTable [class*='RaListToolbar-toolbar'] {
    min-height: 10px;
}

.categoriesTable [class*='RaTopToolbar-root'] {
    padding-top: 0;
    padding-bottom: 0;
}

.categoriesTable [class*='RaDatagrid-headerCell'] {
    font-weight: 700;
    text-transform: capitalize;
}