.incidentsTable [class*='RaFilterForm-form'] {
    margin-top: 0;
    min-height: 0;
}

.incidentsTable [class*='RaFilterFormInput-body'] {
    display: block;
}

.incidentsTable [class*='RaSearchInput-input'] {
    margin-top: 0;
}
