.incidentsFilter [class*='RaFilterFormInput-body'] {
    display: grid;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
}

.incidentsFilter [class*='RaFilterFormInput-body'] [class*='MuiFormControl-root'] {
    margin-top: 0;
    margin-bottom: 0;
}


.incidentsFilter [class*='RaFilterFormInput-body'] [class*='MuiInput-formControl'] [id*='ticketing_manager'] {
    margin-top: 20px
}