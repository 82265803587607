.incidentsTable {
    position: relative;
}

.incidentsTable .MuiCard-root {
    box-shadow: none;
}

.incidentsTable [class*='RaListToolbar-actions'] {
    padding-top: 0;
    padding-bottom: 0;
}

.incidentsTable [class*='RaListToolbar-toolbar'] {
    min-height: 10px;
}

.incidentsTable [class*='RaTopToolbar-root'] {
    padding-top: 0;
    padding-bottom: 0;
}
