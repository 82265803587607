.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

#rich-text-show h1 {
    font-family: Roboto, serif;
}

#rich-text-show h2 {
    font-family: Roboto, serif;
}

#rich-text-show h3 {
    font-family: Roboto, serif;
}

#rich-text-show h4 {
    font-family: Roboto, serif;
}

#rich-text-show h5 {
    font-family: Roboto, serif;
}

#rich-text-show p {
    font-family: Roboto, serif;
}

#rich-text-show img {
    max-width: 100%;
}
