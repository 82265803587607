.ql-container {
    font-size: 16px;
}

.ql-editor {
    min-height: 300px;
}

.ql-editor > p {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h1 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h2 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h3 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h4 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h5 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > h6 {
    line-height: 1.5 !important;
    margin-bottom: 10px;
}

.ql-editor > p > img {
    margin-top: 5px;
}
